import type { Environment } from './types';
import { sharedProdEnv, sharedCompoundUkEnv, sharedProdEuEnv } from './shared';

export const env: Environment = {
  ...sharedProdEnv,
  ...sharedCompoundUkEnv,
  ...sharedProdEuEnv,
  adminAppUrl: 'https://admin.uk.compound.co',
  logoUrl:
    'https://storage.googleapis.com/assets-compound-uk/compound-logo.svg',
};
